import { onMounted, onBeforeUnmount } from 'vue';
import { useAnalytics } from '@/analytics/compositions/useAnalytics';

export default (eventName, eventPayload, measureIntervals = 10000) => {
  const events = ['pointerdown', 'pointermove', 'keypress'];
  let movementDetected = false;
  let pingTimeInterval;
  let lastMovementTimestamp;
  const { logEvent } = useAnalytics();

  onMounted(() => {
    pingTimeInterval = setInterval(handleIntervaleEnd, measureIntervals);
    for (const event of events) {
      document.addEventListener(event, activityRecorded);
    }
  });

  onBeforeUnmount(() => {
    clearInterval(pingTimeInterval);
    for (const event of events) {
      document.removeEventListener(event, activityRecorded);
    }
  });

  const handleIntervaleEnd = () => {
    if (movementDetected && eventName) {
      logEvent(eventName, {
        ...eventPayload,
      });
      lastMovementTimestamp = null;
    }
    movementDetected = false;
  };

  const activityRecorded = () => {
    movementDetected = true;
    if (!lastMovementTimestamp) lastMovementTimestamp = new Date();
  };
};
