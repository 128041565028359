export const PUR_ANALYTICS_EVENT_TYPES = {
  ORDER_PROCESS: {
    CREATE: 'order create',
    UPDATE: 'order update',
    DELETE: 'order delete',
    RESET: 'order reset',
    METHOD: 'order method',
    CATALOG_FILER: 'catalog search & filter',
    SUMMARY: 'order summary',
    COMPLETE: 'order complete',
    NOTE: 'order notes',
    EXIT: 'order exit',
    ARRIVAL_DATE: 'delivery date changed',
    ENTERED: 'order entered',
  },
};
