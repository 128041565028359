import { ANALYTICS_EVENT_TYPES } from './types';

export const isValidAnalyticsEventName = (eventName) => {
  const checkEvent = (currentObj) => {
    for (const key in currentObj) {
      if (typeof currentObj[key] === 'object') {
        if (checkEvent(currentObj[key])) {
          return true;
        }
      } else if (currentObj[key] === eventName) {
        return true;
      }
    }
    return false;
  };

  return checkEvent(ANALYTICS_EVENT_TYPES);
};
