<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.7929 18.2071C11.1834 18.5976 11.8166 18.5976 12.2071 18.2071C12.5976 17.8166 12.5976 17.1834 12.2071 16.7929L8.41422 13L18 13C18.5523 13 19 12.5523 19 12C19 11.4477 18.5523 11 18 11L8.41422 11L12.2071 7.20711C12.5976 6.81658 12.5976 6.18342 12.2071 5.79289C11.8166 5.40237 11.1834 5.40237 10.7929 5.79289L5.29997 11.2858C4.90556 11.6802 4.90553 12.3197 5.29997 12.7142L10.7929 18.2071Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: { type: String, required: false, default: '#1F284D' },
  },
};
</script>
