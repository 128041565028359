<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.79272 6.79307C7.18315 6.40245 7.81632 6.40229 8.20693 6.79272L15 13.5825V8.22182C15 7.66954 15.4477 7.22182 16 7.22182C16.5523 7.22182 17 7.66954 17 8.22182V15.99C17 16.5479 16.5477 17 15.9901 17L8.22183 17C7.66954 17 7.22183 16.5523 7.22183 16C7.22183 15.4477 7.66954 15 8.22183 15H13.5891L6.79307 8.20728C6.40245 7.81685 6.40229 7.18368 6.79272 6.79307Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: { type: String, required: false, default: '#006A37' },
    size: { type: Number, required: false, default: 16 },
  },
};
</script>
