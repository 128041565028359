<template>
  <svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.589 9L8.2218 9C7.66951 9 7.2218 8.55229 7.2218 8C7.2218 7.44772 7.66951 7 8.2218 7L15.99 7C16.5477 7.00003 17 7.4522 17 8.01L17 15.7782C17 16.3305 16.5523 16.7782 16 16.7782C15.4477 16.7782 15 16.3305 15 15.7782L15 10.4175L8.20693 17.2072C7.81632 17.5977 7.18315 17.5975 6.79272 17.2069C6.40229 16.8163 6.40245 16.1831 6.79307 15.7927L13.589 9Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: {
    fill: { type: String, required: false, default: '#BB0021' },
    size: { type: Number, required: false, default: 16 },
  },
};
</script>
