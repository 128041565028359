import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { add, init } from '@amplitude/analytics-browser';
import config from '@/config';

const isProdOrStg = ['production', 'integration', 'staging'].includes(config.NODE_ENV);

function initializeAnalytics() {
  if (isProdOrStg) {
    if (config.NODE_ENV === 'production') {
      const sessionReplay = sessionReplayPlugin();
      add(sessionReplay);
    }

    init(config.VUE_APP_ANALYTICS_API_KEY, {
      defaultTracking: {
        pageViews: true,
        sessions: true,
      },
    });
  }
}

initializeAnalytics();
