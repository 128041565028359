import { useUser } from '@/modules/auth';
import { setUserId, logEvent as logEventAnalytics } from '@amplitude/analytics-browser';
import { watch } from 'vue';
import { isValidAnalyticsEventName } from '../analytics-helpers';
import config from '@/config';

const isProdOrStg = ['production', 'integration', 'staging'].includes(config.NODE_ENV);

export function useAnalytics() {
  const { user } = useUser();

  const setUserDetails = () => {
    if (user?.value?.username) {
      setUserId(user.value.username);
    }
  };

  watch(user, setUserDetails, { immediate: true });

  const logEvent = (eventName, eventProperties = {}) => {
    if (isProdOrStg && isValidAnalyticsEventName(eventName)) {
      try {
        logEventAnalytics(eventName, { ...eventProperties, analyticsSource: 'web' });
      } catch (error) {
        console.error(`Error logging event ${eventName}`, error);
      }
    }
  };

  return { logEvent };
}
