import { DOC_ANALYTICS_EVENT_TYPES } from './doc-analytics-types';
import { PUR_ANALYTICS_EVENT_TYPES } from './pur-analytics-types';
import { REC_ANALYTICS_EVENT_TYPES } from './rec-analytics-types';

export const ANALYTICS_EVENT_TYPES = {
  PUR: PUR_ANALYTICS_EVENT_TYPES,
  REC: REC_ANALYTICS_EVENT_TYPES,
  DOC: DOC_ANALYTICS_EVENT_TYPES,
  PAGE: {
    ACTIVITY: 'Page Activity',
  },
  TASKS: {
    STARTED: 'Task Started',
    SNOOZED: 'Task Snoozed',
    COMPLETED: 'Task Completed',
    MANAGER_REVIEW_REQUIRED: 'Task Manager Review Required',
    VALIDATION_ERRORS: 'Task Validation Errors',
    VALIDATION_WARNINGS: 'Task Validation Warnings',
    ENDED: 'Task Ended',
  },
};
